import { useDispatch, useSelector } from 'react-redux';

import { MAP_GIRL_FILTERS, updateFilter } from 'redux/ducks/map';
import { classModifier } from 'utils';
import { selectIsMapFilterSelected } from 'redux/selectors/selectors';

import ICONS from 'assets/icons';


const ShowChatEscortsButton = () => {
  const isChatEscortFilterEnabled = useSelector(
    (state) => selectIsMapFilterSelected(state, MAP_GIRL_FILTERS.CHAT_ESCORTS)
  )
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      updateFilter(
        MAP_GIRL_FILTERS.CHAT_ESCORTS,
        [MAP_GIRL_FILTERS.BUFFERED_ESCORTS, MAP_GIRL_FILTERS.CHAT_ESCORTS]
      )
    );
  }

  return (
    <button
      className={classModifier("girls-map__toggle-btn", isChatEscortFilterEnabled && 'active')}
      onClick={handleClick}
      title='Show escorts from active chats'
    >
      Show Chat Escorts
      <ICONS.comments className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default ShowChatEscortsButton;
