import { useDispatch, useSelector } from 'react-redux';
import { MAP_SECONDARY_MODES, updateSecondaryModeId } from 'redux/ducks/map';
import { selectMapState } from 'redux/selectors/selectors';
import { classModifier } from 'utils';


const SimilarEscortsButton = () => {
  const {
    girlIdsBySecondaryMode: {
      [MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT]: hostForSimilarEscortId,
      [MAP_SECONDARY_MODES.TARGET_ESCORT]: targetGirlId,
    },
  } = useSelector(selectMapState)

  const dispatch = useDispatch();

  const handleClick = () => {
    if (hostForSimilarEscortId) {
      dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, null));
    } else {
      dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, targetGirlId));
    }
  }

  return (
    <button
      className={classModifier("girls-map__similar-button", hostForSimilarEscortId && 'active')}
      onClick={handleClick}
    >
      Similar escorts
    </button>
  )
}

export default SimilarEscortsButton;
