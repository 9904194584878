import { useDispatch, useSelector } from 'react-redux';
import { useState, useContext, useEffect } from 'react';

import { GirlsMapModalContext } from '../../GirlsMapModal';
import { LS, classModifier } from 'utils';
import API from 'api/api';

import ICONS from 'assets/icons';
import { updateSubwayLines } from 'redux/ducks/map';


const ShowSubwayLinesButton = () => {
  const [subwayLines, setSubwayLines] = useState([]);
  const isShowSubwayLines = useSelector(state => state.map.subwayLines);

  const { map, maps } = useContext(GirlsMapModalContext);

  const dispatch = useDispatch();

  const classes = classModifier('girls-map__toggle-btn', [
    isShowSubwayLines && 'active',
    'inner-map',
    'subway',
  ])

  const drawSubwayLines = () => {
    API.getSubwayLines()
      .then(({ data }) => {
        const lines = JSON.parse(data).map((line, index) => {
          const lineCoordinates = line.coordinates;

          const polyline = new maps.Polyline({
            path: lineCoordinates,
            strokeColor: line.color,
            strokeWeight: 2,
            map,
          });

          if (!isShowSubwayLines) polyline.setMap(null);

          return {
            id: index,
            polyline: polyline,
            visible: isShowSubwayLines,
          };
        });

        setSubwayLines(lines);
      })
      .catch(console.error);
  };

  const toggleLinesVisibility = () => {
    LS.setItem('showMapSubway', !isShowSubwayLines);

    dispatch(updateSubwayLines(!isShowSubwayLines));

    setSubwayLines((prevLines) => {
      return prevLines.map((line) => {
        const isVisible = !line.visible;

        line.polyline.setMap(isVisible ? map : null);

        return { ...line, visible: isVisible };
      });
    });
  };

  useEffect(() => {
    drawSubwayLines();

    return () => LS.setItem('showMapSubway', null);
  }, []);

  return (
    <button
      className={classes}
      onClick={toggleLinesVisibility}
    >
      <ICONS.subway className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default ShowSubwayLinesButton;
