import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';

import API from 'api/api';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';
import { MAP_LIBRARIES } from './config/config';
import { DEFAULT_ZOOM, MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, resetMapState, updateBufferedGirlIds, updateCenter, updateClientCoordinates, updateGirlsCoordinatesData, updateMapUpdatedState, updatePrimaryMode, updateSecondaryMode, updateSecondaryModeId, updateZoom } from 'redux/ducks/map';
import { selectActiveSession, selectMapSecondaryMode } from 'redux/selectors/selectors';
import { closeModal } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import './GirlsMapModal.scss';
import ICONS from 'assets/icons';
import GirlsMap from './components/GirlsMap/GirlsMap';
import DefaultControls from './components/MapControls/DefaultControls';
import AllEscortsControls from './components/MapControls/AllEscortsControls';
import TargetControls from './components/MapControls/TargetControls';
import NavigationControls from './components/MapControls/NavigationControls';


export const GirlsMapModalContext = React.createContext();

const ControlComponents = {
  [MAP_PRIMARY_MODES.CHAT_ESCORTS]: DefaultControls,
  [MAP_PRIMARY_MODES.ALL_ESCORTS]: AllEscortsControls,
  [MAP_PRIMARY_MODES.TARGET_ESCORT]: TargetControls,
  [MAP_PRIMARY_MODES.NAVIGATION]: NavigationControls,
}

const GirlsMapModal = ({ mapProps = {} }) => {
  const activeSession = useSelector(selectActiveSession);
  const { primaryMode, zoom, updated } = useSelector((state) => state.map);
  const selectionMode = useSelector((state) => selectMapSecondaryMode(state, MAP_SECONDARY_MODES.SELECT_ESCORTS));

  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);

  const dispatch = useDispatch();

  const mapRef = useRef();
  const mapsRef = useRef();
  const shouldStateBeResettedRef = useRef(true);

  const saveMapState = () => shouldStateBeResettedRef.current = false;

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const smoothZoom = (map, maxZoom, currentZoom) => {
    if (currentZoom < maxZoom) {
      const listener = google.maps.event.addListener(map, 'zoom_changed', () => {
        google.maps.event.removeListener(listener);
        smoothZoom(map, maxZoom, currentZoom + 1);
      });
      setTimeout(() => {
        dispatch(updateZoom(DEFAULT_ZOOM));
      }, 100);
    }
  };

  const flyTo = (coordinates, newZoom = zoom, isMarker = false) => {
    const map = mapRef.current;

    if (isMarker) {
      smoothZoom(map, newZoom, map.getZoom())
    } else {
      dispatch(updateZoom(newZoom));
    }

    dispatch(updateCenter(coordinates));
  };

  const fitBounds = (bounds, value) => mapRef.current.fitBounds(bounds, value);

  const findGirlMarkerOnMap = (profile) => {
    return API.getGirlOnMap(profile)
      .then(({ data: findedGirl }) => {
        return new Promise((resolve, reject) => {
          if (!findedGirl) return reject(profile);

          const coordinates = {
            lat: parseFloat(findedGirl.latitude),
            lng: parseFloat(findedGirl.longitude),
          }

          dispatch(updateGirlsCoordinatesData({ data: [findedGirl] }));
          flyTo(coordinates, 21);

          return resolve(findedGirl);
        })
      })
  }

  const flyToCurrentGirlMarkers = (callerIdsOfActiveGirlChats) => {
    return API.getGirlsCoordinatesByIds(callerIdsOfActiveGirlChats)
      .then((data) => {
        dispatch(updateGirlsCoordinatesData(data));

        const girlsFromActiveChats = data.data.filter((caller) => caller.is_default);

        if (!girlsFromActiveChats.length) return;

        if (girlsFromActiveChats.length === 1) {
          flyTo(
            {
              lat: parseFloat(girlsFromActiveChats[0].latitude),
              lng: parseFloat(girlsFromActiveChats[0].longitude)
            }
          );
        } else {
          const bounds = new mapsRef.current.LatLngBounds();

          girlsFromActiveChats.forEach((girl) => {
            bounds.extend({
              lat: parseFloat(girl.latitude),
              lng: parseFloat(girl.longitude),
            })
          })

          fitBounds(bounds, 400)
        }
      })
  }

  useEffect(() => {
    const bufferedUids = Object.values(activeSession?.bufferContactUids || {});

    dispatch(updateBufferedGirlIds(bufferedUids));
  }, [activeSession])

  useEffect(() => {
    const {
      clientLocation,
      activeCallers,
      profile,
    } = mapProps;

    if (clientLocation) {
      dispatch(updateClientCoordinates(mapProps.clientLocation));
    }

    if (profile) {
      dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.TARGET_ESCORT));

      findGirlMarkerOnMap(mapProps.profile)
        .then((findedGirl) => {
          dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.TARGET_ESCORT, true));
          dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.TARGET_ESCORT, findedGirl));
        });
    }

    if (!updated) {
      const idsOfActiveGirlChatsWithDivaDefaultId = (
        activeCallers
          ?.filter(caller => caller.diva_default_id)
          ?.map((caller) => caller.id)
      );

      if (activeCallers) {
        const initialPrimaryMode = idsOfActiveGirlChatsWithDivaDefaultId.length
          ? MAP_PRIMARY_MODES.CHAT_ESCORTS
          : MAP_PRIMARY_MODES.ALL_ESCORTS;
  
        dispatch(updatePrimaryMode(initialPrimaryMode));
      }
  
      if (idsOfActiveGirlChatsWithDivaDefaultId?.length > 0 && !clientLocation) {
        flyToCurrentGirlMarkers(idsOfActiveGirlChatsWithDivaDefaultId);
      }
    }

    dispatch(updateMapUpdatedState());

    return () => shouldStateBeResettedRef.current && dispatch(resetMapState());
  }, [mapProps])

  const handleEscClick = () => dispatch(closeModal());

  const ControlComponent = ControlComponents[primaryMode] || DefaultControls;

  const contextProps = {
    flyTo,
    fitBounds,
    isGoogleMapLoaded,
    setIsGoogleMapLoaded,
    saveMapState,
    findGirlMarkerOnMap,
    map: mapRef.current,
    maps: mapsRef.current,
    shouldStateBeResetted: shouldStateBeResettedRef.current,
  }

  return (
    <GirlsMapModalContext.Provider value={contextProps}>
      <div className={classModifier("girls-map", selectionMode && 'selection-mode')}>
        <button className="girls-map__esc-btn" onClick={handleEscClick}>
          <ICONS.close className="girls-map__esc-btn-icon" /> (Esc)
        </button>

        {isGoogleMapLoaded && <ControlComponent.Header />}
        {isGoogleApiLoaded && <GirlsMap {...{ mapRef, mapsRef }} />}
        {isGoogleMapLoaded && <ControlComponent.Footer />}
      </div>
    </GirlsMapModalContext.Provider>
  );
};

export default GirlsMapModal;
