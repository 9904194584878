import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { classModifier, getContactAvatar } from 'utils';
import { MAP_GIRL_FILTERS, MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, addSecondaryModeId, removeSecondaryModeId, updatePrimaryMode, updateSecondaryMode, updateSecondaryModeId, updateTargetCoordinates } from 'redux/ducks/map';
import { selectMapState, selectMostImportantSecondaryMode } from 'redux/selectors/selectors';
import { GirlsMapModalContext } from '../../GirlsMapModal';

import Marker from '../../../../../../components/UI/Marker/Marker';
import GirlsMarkerTooltip from './GirlsMarkerTooltip';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import ICONS from 'assets/icons';


const GirlsMarker = ({ cluster, supercluster, ...restProps }) => {
  const mostImportantSecondaryMode = useSelector(selectMostImportantSecondaryMode);
  const {
    primaryMode,
    secondaryMode,
    clientData,
    filteredGirlIdsByFilters: {
      [MAP_GIRL_FILTERS.BUFFERED_ESCORTS]: bufferedGirlIds,
      [MAP_GIRL_FILTERS.FINDED_ESCORT]: findedGirlId,
    },
    girlIdsBySecondaryMode: {
      [MAP_SECONDARY_MODES.SELECT_ESCORTS]: selectedGirlIds,
      [MAP_SECONDARY_MODES.TARGET_ESCORT]: targetGirlId,
      [MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT]: hostForSimilarEscortId,
    },
  } = useSelector(selectMapState);
  
  const { saveMapState } = useContext(GirlsMapModalContext);
  
  const [shouldCloseTooltip, setShouldCloseTooltip] = useState(false);
  
  const dispatch = useDispatch();
  
  const girlProperties = cluster.properties;
  const { contact_uid } = girlProperties;

  const isSelectedSecondaryMode = secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS];
  const isTargetMode = primaryMode === MAP_PRIMARY_MODES.TARGET_ESCORT;
  const isNavigationMode = primaryMode === MAP_PRIMARY_MODES.NAVIGATION;
  const isProfileSelected = mostImportantSecondaryMode === MAP_SECONDARY_MODES.SELECT_ESCORTS && selectedGirlIds?.includes(contact_uid)
  const isProfileTargered = mostImportantSecondaryMode === MAP_SECONDARY_MODES.TARGET_ESCORT && targetGirlId === contact_uid;
  const isProfileBuffered = bufferedGirlIds.includes(contact_uid);
  const isProfileFinded = findedGirlId === contact_uid;
  
  const classes = classModifier('girl-marker', girlProperties.available_status && 'available');

  const handleTargetGirl = () => {
    const { latitude, longitude } = clientData;
    const newPrimaryMode = latitude && longitude ? MAP_PRIMARY_MODES.NAVIGATION : MAP_PRIMARY_MODES.TARGET_ESCORT;

    setShouldCloseTooltip(true);
    dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.TARGET_ESCORT, true));
    dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.TARGET_ESCORT, girlProperties));
    dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, null));
    dispatch(updatePrimaryMode(newPrimaryMode));

    setTimeout(() => setShouldCloseTooltip(false), 1000);
  }

  const handleClick = () => {
    if (isTargetMode || isNavigationMode) {
      handleTargetGirl()
    }

    if (!isSelectedSecondaryMode) return;

    if (isProfileSelected) {
      dispatch(removeSecondaryModeId(MAP_SECONDARY_MODES.SELECT_ESCORTS, girlProperties));
    } else {
      dispatch(addSecondaryModeId(MAP_SECONDARY_MODES.SELECT_ESCORTS, girlProperties));
    }
  }

  const girlMarkerTooltip = useMemo(() => (
    <GirlsMarkerTooltip
      girlProperties={girlProperties}
      setShouldCloseTooltip={setShouldCloseTooltip}
      handleTargetGirl={handleTargetGirl}
      saveMapState={saveMapState}
    />
  ), [clientData]);

  const bestMatchPercentageLabel = useMemo(() => {
    const { bestmatch } = girlProperties;
    const classes = classModifier(
      "girls-map__bestmatch-label",
      bestmatch < 50 ? 'low' : bestmatch > 80 ? 'high' : 'medium'
    )

    if (!bestmatch) return null;

    return (
      <mark className={classes}>
        {`${bestmatch}%`}
      </mark>
    )
  }, [cluster])

  return (
    <Marker
      className={classes}
      tooltip={girlMarkerTooltip}
      extraTooltipCondition={!shouldCloseTooltip}
      selected={isProfileSelected || isProfileTargered || isProfileFinded}
      onClickCapture={handleClick}
      isLarge={isProfileTargered || isProfileFinded}
      bottomIcon={isProfileBuffered && ICONS.buffer}
      floatingLabel={isTargetMode && hostForSimilarEscortId && bestMatchPercentageLabel}
      {...restProps}
    >
      <LazyLoadImage
        alt="photo"
        src={getContactAvatar(girlProperties)}
        className="girls-map__marker-img"
      />
    </Marker>
  );
};

export default GirlsMarker;
