import { useDispatch, useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { updateCenter, updateZoom } from 'redux/ducks/map';
import ICONS from 'assets/icons';
import { useDidMount } from 'hooks';


const RecenterButton = ({ targetGirl: { girl: targetGirl } = {}, isTargetMarkerNotVisible }) => {
  const { center: { lat, lng } } = useSelector(state => state.map);
  const dispatch = useDispatch();
  
  const girlsCenter = { lat: targetGirl?.latitude, lng: targetGirl?.longitude }
  const isTargetCoordinatesChanged = (
    Math.abs(lat - targetGirl?.latitude) > 0.00000001
    || Math.abs(lng - targetGirl?.longitude) > 0.00000001
  )

  useDidMount(() => {
    dispatch(updateCenter(girlsCenter));
  })

  const handleClick = () => {
    dispatch(updateCenter(girlsCenter));

    if (isTargetMarkerNotVisible) {
      dispatch(updateZoom(21));
    }
  }

  const classes = classModifier(
    'girls-map__toggle-btn',
    ['inner-map', 'recenter']
  )

  return (
    <button
      className={classes}
      onClick={handleClick}
      disabled={!isTargetCoordinatesChanged}
    >
      <ICONS.location className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default RecenterButton;
