import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { MAP_SECONDARY_MODES, resetMapFilters, updateSecondaryModeId } from 'redux/ducks/map';

import SendEscortsButton from '../ControlElements/SendEscortsButton';
import BackToFullMapButton from '../ControlElements/BackToFullMapButton';


const TargetControls = {};

TargetControls.Header = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetMapFilters());

    return () => dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, null));
  }, [])

  return (
    <div className="girls-map__header">
      <div className="girls-map__buttons-wrap">
        <BackToFullMapButton />
        <SendEscortsButton />
      </div>
    </div>
  )
}

TargetControls.Footer = () => <div className="girls-map__footer"></div>;

export default TargetControls;
